import React, {useState} from "react"
import { Heading, Carousel, BackButton } from "../../components"
import { graphql } from "gatsby"
import styled from "styled-components"

const PhotoPage = ({ data }) => {
  const [tab, setTab] = useState("sports");
  const [idx, setIdx] = useState(0);

  const photos = data.allFile.edges;
  const tabs = [
    {name: "Sports", tab: "sports"}, 
    {name: "Concerts/Events", tab: "events"},
    {name: "Powerlifting", tab: "powerlifting"}];
  const filtered = photos.filter(photo => photo.node.relativeDirectory === `portfolio/photos/${tab}`);

  const tabSwitch = (tab) => {
    setIdx(0)
    document.getElementById("carousel").style.transform = 'translate(0)'
    setTab(tab)
  }

  return (
    <div className="container">
      <Heading title="Photo" cursive="Portfolio" />
      <BackButton />
      <Nav>
        {tabs.map(tabb => (
          <button key={tabb.name} onClick={()=> tabSwitch(tabb.tab)}>{tabb.name}</button>
        ))}
      </Nav>
      <Carousel photos={filtered} idx={idx} setIdx={setIdx} />
    </div>
  )
}

export default PhotoPage

export const Head = () => <title>TMOY || Photos</title>

export const photosQuery = graphql`
  query {
    allFile(
      filter: {extension: {eq: "jpg"}, 
      relativePath: {regex: "/portfolio/photos/" }}
      sort: { fields: [base] }) {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`
const Nav = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: -2rem;

  button {
    font-size: 12pt;
    border-radius: 10px;
    background-color: ${props => props.theme.bg};
    width: 100%;
    border: 1px solid ${props => props.theme.secondary};
    padding: 10px;
    margin: 10px;
    font-weight: bold;

    :hover {
      border: 1px solid ${props => props.theme.accent};
      color: ${props => props.theme.accent};
    }
  }
`